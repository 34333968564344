<script>
import {
  BButton,
  BCard,
  BCol,
  BListGroup,
  BListGroupItem,
  BPopover,
  BRow,
} from "bootstrap-vue";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import { mapState } from "vuex";
import KBCharger from "@/views/components/whitelabel-templates/KB/KBCharger.vue";
import KBGeneralEdit from "@/views/components/whitelabel-templates/KB/config/GeneralEdit/GeneralEdit.vue";
import KBPageEdit from "@/views/components/whitelabel-templates/KB/config/PageEdit/PageEdit.vue";
import TemplateNavMenu from "@/views/components/whitelabel-templates/common/TemplateNavMenu.vue";
import TemplateSizeSelector from "@/views/components/whitelabel-templates/common/TemplateSizeSelector.vue";
import UserProfile from "@/views/components/whitelabel-templates/common/UserProfile/UserProfile.vue";
import LoaderTemplate from "@/views/components/whitelabel-templates/common/loaderTemplate.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";

const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "index",
  components: {
    UserProfile,
    TemplateNavMenu,
    TemplateSizeSelector,
    KBPageEdit,
    KBGeneralEdit,
    KBCharger,
    BPopover,
    BListGroupItem,
    BListGroup,
    BButton,

    BCol,
    BRow,
    BCard,
    LoaderTemplate
    
  },
  data() {
    return {
      size: "monitor",
      errorFetchingTemplate: false,
      menu: [
        { icon: "EditIcon", window: "g-edit", name: "General edit" },
        { icon: "ColumnsIcon", window: "p-edit", name: "Layout edit" },
      ],
      selectedMenuWindow: "",
      hoverEditComponent: "",
      closed: false,
    };
  },
  computed: {
    ...mapState("app-whitelabel-templates", [
      "loadingFetchTemplate",
      "editTemplate",
      "reRenderTemplate",
    ]),
   
    getWhitelabelSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },

  watch: {
    getWhitelabelSelect: {
      handler(newWhitelabel, oldWhitelabel) {
        if (newWhitelabel._id !== oldWhitelabel._id) this.fetchTemplateData();
      },
    },
  },

  methods: {
    onToggleSidebar(closed) {
      this.closed = closed;
    },

    handleMenuWindow(menu) {
      this.selectedMenuWindow = this.selectedMenuWindow === menu ? "" : menu;
    },

    async fetchTemplateData() {
      const id = this.$route.query.id;
      const params = {
        whitelabelId: this.getWhitelabelSelect._id,
      };
      this.errorFetchingTemplate = false;
      try {
        let { data: template } = await this.$store.dispatch(
          `${MODULE_APP_STORE_NAME}/fetchTemplate`,
          { id, params }
        );

        this.$store.commit(
          `${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE`,
          template
        );
        this.$store.commit(
          `${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE_RECOVERY`,
          JSON.parse(JSON.stringify(template))
        );
      } catch (e) {
        this.errorFetchingTemplate = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: await translatableText({text: axiosErrorHandle(e).toString()}),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        //TODO DELETE FINALLY
        // this.$store.commit(
        //   "app-whitelabel-templates/SET_EDIT_TEMPLATE",
        //   this.template
        // );
        // this.$store.commit(
        //   "app-whitelabel-templates/SET_EDIT_TEMPLATE_RECOVERY",
        //   JSON.parse(JSON.stringify(this.template))
        // );
        // this.errorFetchingTemplate = false;
      }
    },
  },

  created() {
    store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },

  async mounted() {
    this.fetchTemplateData();
  },

  beforeCreate() {
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
  },

  // beforeDestroy() {
  //   if (store.hasModule(MODULE_APP_STORE_NAME))
  //     store.unregisterModule(MODULE_APP_STORE_NAME);
  // },
};
</script>

<template>

  <div class="template-container">
    <!-- Loading status -->
    <!-- <div v-if="loadingFetchTemplate" class="loading-state">Cargando...</div> -->
    <LoaderTemplate v-if="loadingFetchTemplate"/>

    <!-- Error status -->
    <div v-else-if="errorFetchingTemplate" class="error-state">
      Error, Reintentar...
    </div>

    <div v-else-if="editTemplate" class="main-content d-flex flex-column">
      <!-- Size selector -->
      <template-size-selector
        :sizes="['smartphone', 'tablet', 'square', 'monitor']"
        @selectSize="(size) => (this.size = size)"
      />

      <section class="wrapper">
        <!-- Application loading component -->
        <k-b-charger
            :hover-edit-component="hoverEditComponent"
            :re-render="reRenderTemplate"
            :size="size"
        />

        <!-- Navigation Container -->
        <TemplateNavMenu
          :menu="menu"
          :selected-menu-window="selectedMenuWindow"
          :closed="closed"
          @menu-window-selected="handleMenuWindow"
          @toggle-sidebar="onToggleSidebar"
        />

        <!-- Editing components -->
        <k-b-general-edit v-show="selectedMenuWindow === 'g-edit'" />
        <k-b-page-edit v-show="selectedMenuWindow === 'p-edit'" />
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.main-content {
  height: 80vh;
  gap: 5px;
}

.size-selector {
  padding: 10px;
}
</style>
